import React from 'react'

// Gatsby
import { Link } from 'gatsby'

// Images
import Me from '@images/me.png'

// Components
import Navbar from '@components/Navbar'

const Header = () => (
  <header className='header'>
    <div className='container'>
      <div className='header__inner'>
        <Link className='header__link' to='/'>
          <img src={Me} alt='me' />
          <span className='header__link-text'>ysoftaoglu.com</span>
        </Link>
        <Navbar />
      </div>
    </div>
  </header>
)

export default Header
