import React from 'react'

// Gatsby
import { Link } from 'gatsby'

// Components
import ThemeSwitcher from '@components/ThemeSwitcher'

const Navbar = () => (
  <nav className='navbar'>
    <Link activeClassName='navbar__item--active' className='navbar__item' to='/posts/' partiallyActive>
      Yazılar
    </Link>
    <Link activeClassName='navbar__item--active' className='navbar__item' to='/notes/' partiallyActive>
      Notlar
    </Link>
    <Link activeClassName='navbar__item--active' className='navbar__item' to='/tags/' partiallyActive>
      Etiketler
    </Link>
    <Link activeClassName='navbar__item--active' className='navbar__item' to='/about/' partiallyActive>
      Hakkında
    </Link>
    <ThemeSwitcher />
  </nav>
)

export default Navbar
