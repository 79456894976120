import { graphql, useStaticQuery } from 'gatsby'

export const useSiteMetadata = () => {
  const {
    site: { siteMetadata }
  } = useStaticQuery(
    graphql`
      query SiteMetadaQuery {
        site {
          siteMetadata {
            description
            siteUrl
            socialMedias {
              facebookUrl
              instagramUrl
              linkedinUrl
              githubUrl
              codepenUrl
              behanceUrl
            }
            title
            titleTemplate
          }
        }
      }
    `
  )

  return siteMetadata
}
