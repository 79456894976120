import React from 'react'

// Components
import { Helmet } from 'react-helmet'
import { Location } from '@reach/router'

// Utils
import { useSiteMetadata } from '@utils/useSiteMetadata'

const SEO = ({
  title,
  slug,
  ogImage,
  description,
  keywords,
  isArticle = false,
  dateTime = null,
  noIndexPage = false
}) => {
  const { titleTemplate, siteUrl } = useSiteMetadata()

  let firstArticleTag = null

  if (isArticle) firstArticleTag = keywords.split(', ')[0]

  const ogImageUrl = isArticle ? `${siteUrl}/og-images/${slug.slice(1, -1)}.png` : `${siteUrl}/og-image.png`

  return (
    <Location>
      {({ location }) => (
        <Helmet title={title} titleTemplate={titleTemplate}>
          <html lang='tr' />
          <meta name='google-site-verification' content='9Q6DDV19lxNg_oYCTOpZLRL7XHDmgUX4dQoxjCc_gHA' />
          <meta name='author' content='Yasin Softaoğlu' />
          <meta name='description' content={description} />
          <meta name='keywords' content={keywords} />
          <meta property='og:type' content={isArticle ? 'article' : 'website'} />
          <meta property='og:image' content={ogImageUrl} />
          <meta property='og:url' content={location.href} />
          <meta property='og:title' content={title} />
          <meta property='og:description' content={description} />
          <meta
            property='og:site_name'
            content='Frontend development ile ilgili güncel ve faydalı yazılar, içerikler, ipuçları'
          />

          {noIndexPage && <meta name='robots' content='noindex' />}
          {isArticle && firstArticleTag && <meta property='article:tag' content={keywords.split(', ')[0]} />}
          {isArticle && <meta property='article:author' content='Yasin Softaoğlu' />}
          {dateTime && <meta property='article:published_time' content={dateTime} />}
        </Helmet>
      )}
    </Location>
  )
}

export default SEO
