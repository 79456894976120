import React, { useContext } from 'react'

// Context
import ThemeContext from '@context/ThemeContext'

const ThemeSwitcher = () => {
  const { isDarkTheme, switchTheme } = useContext(ThemeContext)

  return (
    <button aria-label='Gece Modu Ayarı' className='theme-switcher' onClick={switchTheme}>
      <i className={`i-${isDarkTheme ? 'sun' : 'moon'}`} />
    </button>
  )
}

export default ThemeSwitcher
