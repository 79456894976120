import React from 'react'

// Components
import Header from '@components/Header'

// Context
import { ThemeProvider } from '@context/ThemeContext'

// Base Styling
import '../../styles/index.sass'

const MainLayout = ({ children }) => (
  <div className='base-wrapper'>
    <ThemeProvider>
      <Header />
    </ThemeProvider>
    <main className='main'>
      <div className='container'>{children}</div>
    </main>
  </div>
)

export default MainLayout
