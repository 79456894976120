import React, { useLayoutEffect, useState } from 'react'

const ThemeContext = React.createContext({
  isDarkTheme: false,
  switchTheme: () => {}
})

export const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setTheme] = useState(false)

  const switchTheme = () => setTheme(!isDarkTheme)

  useLayoutEffect(() => {
    const userThemePreference = JSON.parse(localStorage.getItem('isDarkMode')) || false

    setTheme(userThemePreference)
  }, [])

  useLayoutEffect(() => {
    localStorage.setItem('isDarkMode', isDarkTheme)

    document.body.setAttribute('class', isDarkTheme ? 'dark' : '')
  }, [isDarkTheme])

  return (
    <ThemeContext.Provider
      value={{
        isDarkTheme,
        switchTheme
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext
